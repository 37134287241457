const DELIST_COLLECTIONS = {
  '0x4D89eBf7b1F66806A4C99Df71D22b7b9efC6bB0b': {
    active: false,
    address: '0x4D89eBf7b1F66806A4C99Df71D22b7b9efC6bB0b',
    avatar: '',
    banner: {
      large: '',
      small: '',
    },
    createdAt: '',
    creatorAddress: '',
    creatorFee: '',
    description: '',
    id: '0x4D89eBf7b1F66806A4C99Df71D22b7b9efC6bB0b',
    name: 'Baked Potatoes',
    numberTokensListed: '76',
    owner: '0xCd1B0abBc3E55E91FCC5AEE393525e68478C2952',
    symbol: 'POT',
    totalSupply: '',
    totalTrades: '',
    totalVolumeBNB: '',
    tradingFee: '',
    updatedAt: '',
    verified: true,
    whitelistChecker: '',
  },
  '0xC448498DDC536ad6F5d437325725dCf504d2d964': {
    active: false,
    address: '0xC448498DDC536ad6F5d437325725dCf504d2d964',
    avatar: '',
    banner: {
      large: '',
      small: '',
    },
    createdAt: '',
    creatorAddress: '',
    creatorFee: '',
    description: '',
    id: '0xC448498DDC536ad6F5d437325725dCf504d2d964',
    name: 'Catbread',
    numberTokensListed: '9385',
    owner: '0xC9A81c56eb07397989907fa6BcC121B593d8f141',
    symbol: 'CATBREADNFT',
    totalSupply: '',
    totalTrades: '',
    totalVolumeBNB: '',
    tradingFee: '',
    updatedAt: '',
    verified: true,
    whitelistChecker: '',
  },
  '0x44d85770aEa263F9463418708125Cd95e308299B': {
    active: false,
    address: '0x44d85770aEa263F9463418708125Cd95e308299B',
    avatar: '',
    banner: {
      large: '',
      small: '',
    },
    createdAt: '',
    creatorAddress: '',
    creatorFee: '',
    description: '',
    id: '0x44d85770aEa263F9463418708125Cd95e308299B',
    name: 'BornBadBoys',
    numberTokensListed: '1846',
    owner: '0x952B8A6AC7957B769C6cAD934b128f2d27368565',
    symbol: 'BBB',
    totalSupply: '',
    totalTrades: '',
    totalVolumeBNB: '',
    tradingFee: '',
    updatedAt: '',
    verified: true,
    whitelistChecker: '',
  },
} as const

export default DELIST_COLLECTIONS
